import React from 'react'
import ContactForm from './ContactForm'
import { ContactWrapper } from './Styled'
import { Col, Container, Row } from 'react-bootstrap'
import { TextButton, TextWithIcon } from '../../Styled/CustomElements'
import { Box, HeadingStyled, HeadingStyledD, MainHeading, SubHeadingStyled, TextStyled, } from '../../Styled/Elements'
import { ContactVector1, ContactVector2, Email, Phone, Location, Facebook, Snapchat, Instagram, Twitter, Pattern } from '../../Styled/AllImages'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// import Footer from '../../footer/Footer'

const Contact = () => {
	const WhatsApp = () => {
		const phoneNumber = '+966533703343';
		const message = '';
		const whatsappURL = ``;
		// const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
		window.open(whatsappURL, '_blank');
	};
	const Map = ""
	const { t } = useTranslation();
	const socialIcon = [
		{
            img: <Snapchat />,
            link: 'https://www.snapchat.com/',
        },
		{
            img: <Instagram />,
            link: 'https://www.instagram.com/',
        },
        {
            img: <Twitter />,
            link: 'https://x.com/',
        },
        
        
        {
            img: <Facebook />,
            link: 'https://www.facebook.com/',
        },
    ]
	return (
		<>
			<ContactWrapper id='contact' className='contact-bg'>
				<img src={Pattern} className='themePattern contactPattern1' />
				<img src={Pattern} className='themePattern contactPattern2' />
				<Container>
					<Box className='containerContact'>
						<Row className='contactHContainer'>
							<Box className='content-heading text-center'>
								<HeadingStyled className='contactMainHeading main-heading text-center justify-content-center' color='var(--themeColor)'>{t('ContactUs2')}</HeadingStyled>
                                <TextStyled className='contactText mt-2 text-center justify-content-center'>
								{t('ContactUsTxt')}
                                </TextStyled>
							</Box>
						</Row>
						<Row>
							<Col lg={6} md={12} className='contact-Us'>
								<Box className='contact-info'>
									<TextButton clName="dgts" icon={Phone} label='+966556223011' onClick={WhatsApp} color="var(--black)" />
									<TextWithIcon icon={Email} title='info@atharalmadinah.com' href="mailto:info@atharalmadinah.com" color="var(--black)" />
									{/* <TextWithIcon icon={Location} title={t('address')} href={Map} color="var(--black)" />y */}
								</Box>
								<HeadingStyledD className='followHeading main-heading' color='var(--themeColor)'>
								{t('FollowUsOn')}
								</HeadingStyledD>
								<Box className='Social-links'>
									{
										socialIcon.map((list, key) => (
											<a href={list.link} target="_blank" key={key} className='contact-links'>
												{list.img}
											</a>
										))
									}
								</Box>
							</Col>
							<Col lg={6} md={12}>
								<Box className='contect-with-us'>
									<ContactForm />
								</Box>
							</Col>
						</Row>
					</Box>
				</Container>
				{/* <img className='contact-bm-logo' src={Contactlogo} alt='img' /> */}
				{/* <Footer /> */}
			</ContactWrapper>
		</>
	)
}

export default Contact