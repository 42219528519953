import React from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Box, SpanStyled } from '../Styled/Elements'
import { FoooterLogo, FooterInstagram, FooterLinkedin, FooterSnapchat, FooterTikTok, FooterTwitter } from '../Styled/AllImages'
import { NavDataConfig } from '../data/Data'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t, i18n } = useTranslation();
    const FowlWeb = () => {
        window.open('https://websites.fowl.sa/', '_blank');
    };
    const date_year = new Date();
    
    return (
        <FooterWapper className='container-fluid border-top-line'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center gap-2 footer-content justify-space-between footerContentWrapper'>
                        <Box className='footer-logo'>
                            <FoooterLogo />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center align-items-center footerListContainer'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' color='var(--white)' transform='capitalize'>{t('AllRightReserved2')} <span className='dgts'> &copy; {date_year.getFullYear()}</span> {t('MadinaMunawwara')}  </SpanStyled>
                                {/* <SpanStyled className='text-center' color='var(--white)' transform='capitalize'>{t('Developed_by')}<SpanStyled className='fowl' color='var(--themeColor)' onClick={FowlWeb}>{t('Fowl')}</SpanStyled></SpanStyled> */}
                            </Box>
                        </Box>
                        
                    </Box>
                </Row>
            </Container>
        </FooterWapper>
    )
}

export default Footer